import type { StateProposal } from '~/types/proposal'

export const useUserProposal = () => {
  const { $api } = useNuxtApp()
  const proposalState = useState<StateProposal>('proposal')

  const fetchUserProposal = async () => {
    try {
      const includes = ['houses', 'houses.first_photo', 'houses.destination']
      const res = await $api.v3.userAutomaticProposals.read(includes)

      if (res?.data) {
        proposalState.value = {
          ...proposalState.value,
          ...res.data,
          hasExistingProposition: true,
        }
      }

      if (res?.included) {
        const houses = res?.included?.houses || []
        const housesIncluded = Array.isArray(houses) ? houses : [houses]

        const photos = res?.included?.photos || []
        const photosIncluded = Array.isArray(photos) ? photos : [photos]

        const destinations = res?.included?.destinations || []
        const destinationsIncluded = Array.isArray(destinations)
          ? destinations
          : [destinations]

        proposalState.value = {
          ...proposalState.value,
          housesIncluded,
          photosIncluded,
          destinationsIncluded,
        }
      }
    } catch (err) {
      return err
    }
  }

  const cleanProposalState = (b2b2c = false) => {
    proposalState.value = {
      b2b2c,
      automaticProposalHouses: [],
      hasExistingProposition: false,
      id: null,
      toAddHouses: [],
      toDeleteHouses: [],
      housesIncluded: [],
      photosIncluded: [],
      destinationsIncluded: [],
    }
  }

  const updateProposal = async (payload) => {
    try {
      const res = await $api.v3.userAutomaticProposals.update(
        proposalState.value.id,
        {
          automatic_proposal: payload,
        },
      )

      return res
    } catch (err) {
      throw err.response
    }
  }

  const deleteProposal = async (id) => {
    try {
      await $api.v3.userAutomaticProposals.delete(id)

      cleanProposalState()
    } catch (err) {
      return err
    }
  }

  return {
    cleanProposalState,
    deleteProposal,
    fetchUserProposal,
    updateProposal,
  }
}
